<template>
    <div class="">
        <h1 class="home-page__title">
            чтобы начать игру - выберите типаж персонажа
        </h1>
        <transition-group tag="div" class="person-types" name="scale-in">
            <PersonType
                v-show="isLoaded"
                v-for="personType in personTypes"
                :key="personType.name"
                :person-type="personType"
                @update:person="updatePerson"
            >
                <div class="person-types__fund">
                    <p>Ваш капитал</p>
                    <div class="fund">
                        <p>{{stepFund|numberFormat}}</p>
                        <img src="@/assets/images/icons/c.svg" alt="">
                    </div>
                </div>
            </PersonType>
        </transition-group>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PersonType from '@/components/home-page/PersonType';
import numberFormat from '@/core/filters/number-format';

export default {
    filters: {
        numberFormat,
    },
    components: {
        PersonType,
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        ...mapGetters('persons', [
            'personTypes',
        ]),
        ...mapGetters('quiz', ['stepFund']),
    },
    mounted() {
        this.isLoaded = true;
    },
    methods: {
        ...mapActions('persons', [
            'updatePersonType',
        ]),
        async updatePerson(personType) {
            try {
                const person = await this.updatePersonType(personType);
                if (person !== null) {
                    this.$router.push({name: 'Quiz'});
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
