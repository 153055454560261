<template>
    <div class="person-types__data hover-ef" @click="$emit('update:person', personType)">
        <div class="person-types__name">
            {{personType.name}}
        </div>
        <div class="person-types__advantages-container">
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0H15V26H10V0Z" fill="#4AB541"/>
                <path d="M0 15L-2.18557e-07 10L25 10L25 15L0 15Z" fill="#4AB541"/>
            </svg>
            <ul class="person-types__advantages">
                <li
                    v-for="(advantage, index) in personType.advantages"
                    :key="index"
                    class="person-types__advantage"
                >
                    {{advantage}}
                </li>
            </ul>
        </div>
        <div class="person-types__limitations-container">
            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 13L-2.18557e-07 8L25 8L25 13L0 13Z" fill="#E0000D"/>
            </svg>
            <ul class="person-types__limitations">
                <li
                    v-for="(advantage, index) in personType.limitations"
                    :key="index"
                    class="person-types__limitation"
                >
                    {{advantage}}
                </li>
            </ul>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PersonType',
    props: {
        personType: {
            type: Object,
            required: true,
        },
    },
};
</script>
